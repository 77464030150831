import React from 'react';
import { Bundle } from 'commons';
// import Editor from './Editor';

const loadMod = mod => {
    import('./Editor').then(mod);
};

const ModBundle = props => (
    <Bundle load={loadMod}>
        {Mod => <Mod {...props} />}
    </Bundle>
);

export default ModBundle;
